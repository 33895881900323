<template>
  <div>
    <h4 class="mb-4 font-weight-semibold">{{ title }}</h4>
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div class="table-responsive">
          <table class="table row table-borderless w-100 m-0 text-nowrap">
            <tbody class="col-lg-12 col-xl-12 p-0">
              <tr v-for="(item, i) in list" :key="'experience' + i">
                <td style="padding: 3px">
                  <li style="list-style: disc">
                    {{ item.title }}
                    <!--<a
                      v-if="item.file != null"
                      @click="showInfo(item)"
                      target="_blank"
                      ><i class="fa fa-search"></i
                    ></a>-->
                  </li>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      id="videoModal"
    >
      <div class="modal-dialog modal-lg w-100" role="document">
        <div class="modal-content">
          <div class="modal-body" id="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "list"],
  created() {},
  data() {
    return {
      index: null,
      file:""
    };
  },
  methods: {
    showInfo(item) {
      this.index = item;
      this.file=item.file
      document.getElementById('content').innerHTML='<iframe height="500" src="https://storage.terapivitrini.com/' + this.file+'" width="100%"/>'
      setTimeout(() => {
        $("#videoModal").modal("show");
      }, 500);
    },
  },
};
</script>