<template>
  <div>
    <!--Breadcrumb-->
    <div id="append" @click="closeAppointment"></div>

    <Breadcrumb
      title="Merkez"
      :array="[
        { islink: true, url: '/', text: 'Ana Sayfa' },
        { islink: true, url: '/center', text: 'Merkez Listesi' },
        { islink: false, url: '', text: list.fullname },
      ]"
    />
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-7 col-md-12">
            <div class="card overflow-hidden" v-if="isLoadUser">
              <div class="card-body item-user">
                <div class="profile-pic mb-0">
                  <div class="d-md-flex">
                    <div class="skeleton w-150 h-150 br-2" alt="user"></div>
                    <div class="ms-4 w-50">
                      <h1
                        class="skeleton mt-3 p-3 mb-1 font-weight-bold"
                        style="font-size: revert"
                      ></h1>
                      <div class="skeleton p-2 w-70 mb-3"></div>
                      <div class="skeleton text-muted p-3 mt-2"></div>
                      <div class="row">
                        <div class="col-6">
                          <div class="skeleton text-muted p-3 mt-1"></div>
                        </div>
                        <div class="col-6">
                          <div class="skeleton text-muted p-3 mt-1"></div>
                        </div>
                        <div class="col-6">
                          <div class="skeleton text-muted p-3 mt-1"></div>
                        </div>
                        <div class="col-6">
                          <div class="skeleton text-muted p-3 mt-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="nav nav-pills nav-fill">
                <div class="nav-item" v-for="item in 4" :key="item">
                  <div
                    class="skeleton p-4 w-100 active"
                    style="margin-left: 10px; margin-right: 10px"
                  ></div>
                </div>
              </ul>
              <div class="card-body border-top">
                <h4 class="skeleton mb-4 font-weight-semibold p-3"></h4>
                <div class="mb-4">
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-100"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-70"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-30"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-60"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-20"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-70"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-30"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-60"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-20"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-70"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-30"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-60"
                  ></div>
                  <div
                    class="skeleton mb-4 font-weight-semibold p-1 w-20"
                  ></div>
                </div>
              </div>
            </div>
            <div class="card overflow-hidden" v-if="!isLoadUser">
              <div
                class="card-body item-user"
                style="padding-bottom: 10px !important"
              >
                <div class="profile-pic mb-0">
                  <div class="d-md-flex">
                    <img
                      :src="
                        'https://storage.terapivitrini.com/' +
                        list.profile_image_url
                      "
                      class="w-150 h-150 br-2 profile_img"
                      :alt="list.fullname"
                    />

                    <div class="ms-4">
                      <h1
                        class="mb-1 font-weight-bold"
                        style="font-size: revert"
                      >
                        {{ list.fullname }}
                      </h1>
                      <span>{{ list.expert }}</span>
                      <small
                        v-if="list.approved_profile != 1 || list.delete == 1"
                        >Bu profili yalnızca siz görüntüleyebilirsiniz. Profil
                        Durumu: <b>Onaysız</b></small
                      >
                      <div class="text-muted mb-1">
                        <StarPos
                          :pos="
                            Math.ceil(list.total_score / list.total_comment)
                          "
                          :comment="list.total_comment"
                          :id="list.user_id"
                        />
                      </div>
                      <div class="row">
                        <div class="col-6 mt-2">
                          <div
                            @click="createAppointmentHelper"
                            class="w-100 btn btn-primary btn-sm"
                          >
                            <i class="fa fa-user"></i> Kadromuz
                          </div>
                        </div>
                        <div class="col-6 mt-2">
                          <div
                            class="w-100 btn btn-primary btn-sm"
                            @click="callNow"
                          >
                            <i class="fa fa-phone"></i> {{ phoneDialogText }}
                          </div>
                        </div>
                        <div class="col-6 mt-2">
                          <div class="w-100 btn btn-warning btn-sm">
                            Görüntüleme ({{ list.profile_view_count }})
                          </div>
                        </div>
                        <div class="col-6 mt-2">
                          <div class="w-100 btn btn-warning btn-sm">
                            Danışan ({{ list.client_count }})
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="nav nav-pills nav-fill">
                <div
                  :class="
                    'nav-item' +
                    (selectedView == 'GENERALINFO' ? ' activeTab' : '')
                  "
                >
                  <a
                    class="btn"
                    style="margin: 0 auto"
                    @click="selectedViewChange('GENERALINFO')"
                  >
                    Genel
                  </a>
                </div>
                <div
                  :class="
                    'nav-item' + (selectedView == 'ADDRESS' ? ' activeTab' : '')
                  "
                >
                  <a
                    class="btn"
                    style="margin: 0 auto"
                    @click="selectedViewChange('ADDRESS')"
                  >
                    Adres
                  </a>
                </div>
                <div
                  :class="
                    'nav-item' + (selectedView == 'SERVICE' ? ' activeTab' : '')
                  "
                >
                  <a
                    class="btn"
                    style="margin: 0 auto"
                    @click="selectedViewChange('SERVICE')"
                  >
                    Hizmet
                  </a>
                </div>
                <div
                  v-if="list.educations.length > 0"
                  :class="
                    'nav-item' +
                    (selectedView == 'EDUCATION' ? ' activeTab' : '')
                  "
                >
                  <a
                    class="btn"
                    style="margin: 0 auto"
                    @click="selectedViewChange('EDUCATION')"
                  >
                    Eğitim
                  </a>
                </div>
                <div
                  :class="
                    'nav-item' +
                    (selectedView == 'COMMENTS' ? ' activeTab' : '')
                  "
                >
                  <a
                    class="btn"
                    style="margin: 0 auto"
                    @click="selectedViewChange('COMMENTS')"
                  >
                    <template v-if="list.total_comment > 0">
                      Görüş ({{ list.total_comment }})
                    </template>
                    <template v-else> Görüş </template>
                  </a>
                </div>
              </ul>

              <template v-if="selectedView == 'ADDRESS'">
                <div
                  class="card-body border-top"
                  id="ADDRESS"
                  v-if="list.address != null"
                >
                  <div class="item-det">
                    <div class="">
                      <div
                        class="mb-2 mt-3"
                        v-for="(item, i) in list.address"
                        :key="'address' + i"
                      >
                        <div class="col-sm-12 col-md-12">
                          <a
                            target="_blank"
                            :href="
                              'https://www.google.com/maps/search/' +
                              item.address +
                              ' ' +
                              item.province +
                              '/' +
                              item.city
                            "
                          >
                            <h4 class="font-weight-bold">
                              {{ item.address_title }}
                            </h4>
                            <div class="icons mb-4">
                              <i class="si si-location-pin text-muted me-1"></i>
                              {{ item.address }}
                              {{ item.province }} / {{ item.city }}
                            </div>
                          </a>
                          <GmapMap
                            ref="mapRef"
                            :center="JSON.parse(item.location)"
                            :zoom="18"
                            style="width: 100%; height: 400px"
                          >
                            <GmapMarker
                              :position="JSON.parse(item.location)"
                              :clickable="false"
                              :draggable="false"
                              :icon="'https://terapivitrini.com/assets/images/marker.png'"
                            />
                          </GmapMap>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="selectedView == 'SERVICE'">
                <template v-if="list.experience != null">
                  <div class="card-body border-top" id="SERVICE">
                    <div class="list-id">
                      <div class="row">
                        <div class="col-xl-12 col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered border-top mb-0">
                              <thead>
                                <tr class="text-center font-weight-semibold">
                                  <th>Terapİ</th>
                                  <th>Tür</th>

                                  <th>Yüz Yüze</th>
                                  <th>Online</th>
                                  <th>Evde</th>
                                </tr>
                              </thead>

                              <tbody v-if="list.experience.length > 0">
                                <tr
                                  v-for="(item, i) in list.experience"
                                  :key="'experience' + i"
                                  class="text-center"
                                >
                                  <td>
                                    <span class=""
                                      ><router-link
                                        :to="'/experiences/' + item.short_name"
                                      >
                                        {{ item.experience_name }}</router-link
                                      ></span
                                    >
                                  </td>
                                  <td>
                                    {{ capitalizeFirstLetter(item.type) }}
                                  </td>
                                  <td class="text-center">
                                    <i
                                      v-if="item.face_to_face == 1"
                                      class="fa fa-check"
                                    ></i>
                                  </td>

                                  <td class="text-center">
                                    <i
                                      v-if="item.online == 1"
                                      class="fa fa-check"
                                    ></i>
                                  </td>
                                  <td class="text-center">
                                    <i
                                      v-if="item.home_service == 1"
                                      class="fa fa-check"
                                    ></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="selectedView == 'EDUCATION'">
                <template v-if="list.educations != null">
                  <div class="card-body border-top" id="SERVICE">
                    <div class="list-id">
                      <div class="row">
                        <div class="col-xl-12 col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered border-top mb-0">
                              <thead>
                                <tr class="text-center font-weight-semibold">
                                  <th>Eğitim</th>
                                  <th>Süre</th>
                                  <th>Tür</th>
                                  <th>Tarih</th>
                                  <th>Fiyat</th>
                                </tr>
                              </thead>

                              <tbody v-if="list.educations.length > 0">
                                <tr
                                  v-for="(item, i) in list.educations"
                                  :key="'experience' + i"
                                  class="text-center"
                                >
                                  <td>
                                    <span class=""
                                      ><router-link
                                        :to="
                                          '/education/' +
                                          item.shortlink +
                                          '/' +
                                          item.id
                                        "
                                      >
                                        {{ item.title }}</router-link
                                      ></span
                                    >
                                  </td>
                                  <td>{{ item.duration }} Saat</td>

                                  <td class="text-center">
                                    {{ item.type == 1 ? "Yüz yüze" : "Online" }}
                                  </td>

                                  <td class="text-center">
                                    {{ item.begin_date }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.price }}TL
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="selectedView == 'GENERALINFO'">
                <div class="card-body border-top" id="GENERALINFO">
                  <template v-if="list.about != null">
                    <h4 class="mb-4 font-weight-semibold">Hakkımızda</h4>
                    <div class="mb-4" v-html="list.about"></div>
                    <hr />
                  </template>

                  <div class="row w-100">
                    <template v-if="list.skill.length > 0">
                      <Info
                        class="col-xl-6 col-md-6"
                        title="İlgi Alanları"
                        :list="list.skill"
                      />
                    </template>
                    <hr
                      v-if="list.certificate.length > 0"
                      id="showLine"
                      style="
                        margin: 0 auto;
                        margin-top: 20px;
                        display: none;
                        margin-bottom: 20px;
                      "
                    />
                    <template v-if="list.certificate.length > 0">
                      <Info
                        class="col-xl-6 col-md-6"
                        title="Sertifikalar"
                        :list="list.certificate"
                      />
                    </template>
                    <hr
                      v-if="list.education.length > 0"
                      style="
                        margin: 0 auto;
                        margin-top: 20px;
                        margin-bottom: 20px;
                      "
                    />
                    <template v-if="list.education.length > 0">
                      <Info
                        class="col-xl-6 col-md-6"
                        title="Eğitimler"
                        :list="list.education"
                      />
                    </template>
                    <hr
                      v-if="list.language.length > 0"
                      id="showLine"
                      style="
                        margin: 0 auto;
                        margin-top: 20px;
                        display: none;
                        margin-bottom: 20px;
                      "
                    />
                    <template v-if="list.language.length > 0">
                      <Info
                        class="col-xl-6 col-md-6"
                        title="Diller"
                        :list="list.language"
                      />
                    </template>
                  </div>

                  <template v-if="list.videos.length > 0">
                    <VideoGallery title="Videolar" :list="list.videos" />
                  </template>
                  <template v-if="list.images.length > 0">
                    <ImageGallery title="Fotoğraflar" :list="list.images" />
                  </template>
                </div>
              </template>

              <div v-if="selectedView == 'COMMENTS'" style="background: #fff">
                <div class="card-body border-top w-100" id="COMMENTS">
                  <Comments class="w-100" />
                  <CommentWrite />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div
                  style="
                    width: fit-content;
                    display: flex;
                    margin: -16px auto;
                    align-content: space-between;
                    justify-content: space-around;
                    align-items: center;
                  "
                >
                  <li v-if="list.facebook != null" class="social-item">
                    <a
                    rel="nofollow"
                      target="_blank"
                      class="social-icon text-dark"
                      :href="list.facebook"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li v-if="list.instagram != null" class="social-item">
                    <a
                    rel="nofollow"
                      target="_blank"
                      class="social-icon text-dark"
                      :href="list.instagram"
                      ><i class="fa fa-instagram"></i
                    ></a>
                  </li>
                  <li v-if="list.twitter != null" class="social-item">
                    <a
                    rel="nofollow"
                      target="_blank"
                      class="social-icon text-dark"
                      :href="list.twitter"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <li v-if="list.linkedin != null" class="social-item">
                    <a
                    rel="nofollow"
                      target="_blank"
                      class="social-icon text-dark"
                      :href="list.linkedin"
                      ><i class="fa fa-linkedin"></i
                    ></a>
                  </li>
                  <li v-if="list.youtube != null" class="social-item">
                    <a
                    rel="nofollow"
                      target="_blank"
                      class="social-icon text-dark"
                      :href="list.youtube"
                      ><i class="fa fa-youtube"></i
                    ></a>
                  </li>
                  <li v-if="list.website != null" class="social-item">
                    <a
                    rel="nofollow"
                      target="_blank"
                      class="social-icon text-dark"
                      :href="list.website"
                      ><i class="fa fa-globe"></i
                    ></a>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <!--Right Side Content-->
          <div class="col-xl-5 col-lg-5 col-md-12 nocon" id="sidebarList">
            <!--KADRO LİSTESİ-->
            <div class="card nocon" id="profile_sidebar">
              <div class="card-header">
                <h4 data-v-b3711b54="" class="mt-2">Kadromuz</h4>
              </div>
              <div
                class="car-body"
                style="
                  padding-left: 16px;
                  padding-right: 16px;
                  padding-top: 16px;
                "
              >
                <ProfileItem :list="list.expert_list" />
              </div>
            </div>
            <div class="card" v-if="list.educations.length > 0">
              <div class="card-header">
                <h4 class="mt-2">Diğer Eğitimler</h4>
              </div>
              <!--/Education Description-->
              <!--Related Posts-->
              <div id="myCarousel" class="owl-carousel owl-carousel-icons">
                <div v-for="(item, i) in list.educations" :key="i">
                  <Item3 :item="item" />
                </div>
              </div>
            </div>

            <div class="card" v-if="list.blogs.length > 0">
              <div class="card-header">
                <h4 class="mt-2">Makaleler</h4>
              </div>
              <!--/Education Description-->
              <!--Related Posts-->
              <div id="myCarousel" class="owl-carousel owl-carousel-icons">
                <div v-for="(item, i) in list.blogs" :key="'blog-' + i">
                  <div class="col-lg-12 col-md-12 col-xl-12">
                    <div class="card">
                      <div class="item7-card-img">
                        <router-link
                          :to="'/blog/' + item.short_name"
                        ></router-link>
                        <lazy-image
                          :src="
                            'https://storage.terapivitrini.com/' +
                            item.thumbnail
                          "
                          :alt="item.title"
                          class="cover-image"
                        />

                        <div class="item7-card-text">
                          <div
                            v-for="(s, i) in item.categories"
                            :key="'categories-' + i"
                          >
                            <router-link :to="'/blog/' + item.short_name">
                              <span class="badge badge-primary">
                                {{ s.category_name }}
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="item7-card-desc d-flex mb-2">
                          <router-link :to="'/blog/' + item.short_name"
                            ><i class="fa fa-calendar-o text-muted me-2"></i
                            >{{ item.created_at }}</router-link
                          >
                          <div class="ms-auto">
                            <router-link :to="'/blog/' + item.short_name"
                              ><i class="fa fa-comment-o text-muted me-2"></i
                              >{{ item.commentcount }}</router-link
                            >
                          </div>
                        </div>
                        <router-link
                          :to="'/blog/' + item.short_name"
                          class="text-dark"
                        >
                          <h4 class="font-weight-semibold">{{ item.title }}</h4>
                        </router-link>
                        <p>{{ item.description }}</p>
                        <router-link
                          :to="'/blog/' + item.short_name"
                          class="ms-auto btn btn-primary btn-sm w-100"
                          >Daha fazla</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/Right Side Content-->
        </div>
      </div>
    </section>
    <!--/Add listing-->
  </div>
</template>
  <style scoped>
@media (max-width: 767px) {
  #showLine {
    display: block !important;
  }
  .profile_img {
    width: 100% !important;
    height: 250% !important;
  }
  .owl-dots {
    display: none !important;
  }
}

.owl-dots {
  display: block !important;
}
.activeTab {
  border-bottom: 2px solid #bb7494;
}
.nav-item {
  margin-left: -5px;
}
.contactItem {
  line-height: 45px;
}
.social-item {
  float: left;
  width: 40px;
  background: #bb7494;
  box-shadow: 0px 5px 5px -2px #404040;
  margin: 10px;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  border-radius: 50px;
}
.activeSelect {
  box-shadow: 0px 0px 20px 0px #bb7494;
  -webkit-transition: 0.7s ease-in;
  -o-transition: 0.7s ease-in;
  transition: 0.7s ease-in;
  position: relative;
  z-index: 5003;
}
.nocon {
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}
.social-item > a {
  color: #fff !important;
}
@media (max-width: 990px) {
  .contactItem {
    line-height: 0px;
    padding-bottom: 15px;
  }
}
</style>
<script>
import Search from "../../components/Search.vue";
import Comments from "./Components/Comments.vue";
import ProfileItem from "./../SearchPages/Components/CenterDetailProfileList.vue";
import Info from "./Components/Info.vue";
import ImageGallery from "./Components/ImageGallery.vue";
import VideoGallery from "./Components/VideoGallery.vue";
import CommentWrite from "./Components/CommentWrite.vue";
import Sidebar from "./Components/Sidebar.vue";
import StarPos from "../../components/StarPos.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import Item3 from "../Education/Components/Item3.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    let generalUserInfo = {
      shortlink: this.$route.params.shortlink,
    };
    this.$store.dispatch("centerGeneralGet", generalUserInfo).then((value) => {
      this.$store.state.selectedProfile = value.users[0];
      this.title = value.users[0].fullname + " - Terapi Vitrini";
      this.description =
        value.users[0].fullname +
        " - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz.";
      let _this = this;
      $(document).ready(function () {
        let content = document.getElementById("appcontent").innerHTML;
        _this.$store.dispatch("setMetaContent", [
          _this.title,
          _this.description,
          "",
          "",
          "",
          content,
        ]);
      });
      this.list = value.users[0];
      this.isLoadUser = false;
    });
  },
  data() {
    return {
      title: "",
      description: "",
      selectedView: "GENERALINFO",
      pos: 0,
      list: [],
      isLoadUser: true,
      phoneShow: false,
      phoneDialogText: "Şimdi Ara",
    };
  },
  methods: {
    run() {
      (function ($) {
        /*---Owl-carousel----*/

        // ______________Owl-carousel-icons
        var owl = $(".owl-carousel-icons");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: true,
          autoplay: true,
          dots: false,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Owl-carousel-icons2
        var owl = $(".owl-carousel-icons2");
        owl.owlCarousel({
          loop: true,
          rewind: false,
          margin: 25,
          animateIn: "fadeInDowm",
          animateOut: "fadeOutDown",
          autoplay: false,
          autoplayTimeout: 5000, // set value to change speed
          autoplayHoverPause: true,
          dots: false,
          nav: true,
          autoplay: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
            600: {
              items: 2,
              nav: true,
            },
            1300: {
              items: 3,
              nav: true,
            },
          },
        });

        // ______________Owl-carousel-icons3
        var owl = $(".owl-carousel-icons3");
        owl.owlCarousel({
          margin: 25,
          loop: false,
          nav: true,
          dots: false,
          autoplay: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 2,
            },
          },
        });

        // ______________Owl-carousel-icons4
        var owl = $(".owl-carousel-icons4");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          dots: false,
          autoplay: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 6,
            },
          },
        });

        // ______________Owl-carousel-icons5
        var owl = $(".owl-carousel-icons5");
        owl.owlCarousel({
          loop: true,
          rewind: false,
          margin: 25,
          animateIn: "fadeInDowm",
          animateOut: "fadeOutDown",
          autoplay: false,
          autoplayTimeout: 5000, // set value to change speed
          autoplayHoverPause: true,
          dots: true,
          nav: false,
          autoplay: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
            600: {
              items: 2,
              nav: true,
            },
            1300: {
              items: 4,
              nav: true,
            },
          },
        });

        // ______________Owl-carousel-icons6
        var owl = $(".owl-carousel-icons6");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          dots: false,
          autoplay: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 3,
            },
          },
        });

        // ______________Testimonial-owl-carousel2
        var owl = $(".testimonial-owl-carousel2");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          autoplay: true,
          dots: false,
          animateOut: "fadeOut",
          smartSpeed: 450,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Testimonial-owl-carousel3
        var owl = $(".testimonial-owl-carousel3");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          autoplay: true,
          dots: true,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Testimonial-owl-carousel4
        var owl = $(".testimonial-owl-carousel4");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          autoplay: true,
          dots: true,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Testimonial-owl-carousel
        var owl = $(".testimonial-owl-carousel");
        owl.owlCarousel({
          loop: true,
          rewind: false,
          margin: 25,
          autoplay: true,
          animateIn: "fadeInDowm",
          animateOut: "fadeOutDown",
          autoplay: false,
          autoplayTimeout: 5000, // set value to change speed
          autoplayHoverPause: true,
          dots: false,
          nav: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
          },
        });
      })(jQuery);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    callNow() {
      if (!this.phoneShow) {
        let generalUserInfo = {
          user_id: this.$store.state.selectedProfile.user_id,
        };
        this.$store.dispatch("callNow", generalUserInfo).then((value) => {
          this.phoneDialogText = value.list;
          this.phoneShow = true;
        });
      } else {
        window.location.href = "tel://" + this.phoneDialogText;
      }
    },
    createAppointmentHelper() {
      $("html, body").animate(
        {
          scrollTop: $("#sidebarList").offset().top - 20,
        },
        0
      );
      document.getElementById("append").style.display = "block";
      document.getElementById("profile_sidebar").classList.add("activeSelect");
      return false;
    },
    closeAppointment() {
      document.getElementById("append").style.display = "none";
      document
        .getElementById("profile_sidebar")
        .classList.remove("activeSelect");
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    selectedViewChange(item) {
      this.selectedView = item;
      setTimeout(() => {
        $("html, body").animate(
          {
            scrollTop: $("#" + item).offset().top - 120,
          },
          0
        );
      }, 300);
    },
  },
  watch: {
    "$route.params.shortlink": function () {
      this.isLoadUser = true;
      let generalUserInfo = {
        shortlink: this.$route.params.shortlink,
      };
      this.$store
        .dispatch("profileGeneralGet", generalUserInfo)
        .then((value) => {
          this.$store.state.selectedProfile = value.users[0];
          this.$store.state.user_id = value.users[0].id;
          document.title = "Terapi Vitrini - " + value.users[0].fullname;
          const meta = document.getElementsByTagName("meta");
          meta.description.content =
            value.users[0].fullname +
            " - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz.";
          this.list = value.users[0];
          this.isLoadUser = false;
        });
    },
  },
  components: {
    Search,
    Comments,
    Info,
    CommentWrite,
    Sidebar,
    VideoGallery,
    ImageGallery,
    StarPos,
    Breadcrumb,
    Item3,
    ProfileItem,
  },
  mounted() {
    this.backToTop();
    setTimeout(() => {
      this.run();
    }, 2000);
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 530 && window.scrollY < 2500) {
        this.pos = window.scrollY;
        /*document.getElementById("profile_sidebar").style.position = "fixed";
          document.getElementById("profile_sidebar").style.width = "inherit";
          document.getElementById("profile_sidebar").style.top = 0;*/
      } else {
        //document.getElementById("profile_sidebar").style.position = "absolute";
        //document.getElementById("profile_sidebar").style.width = "inherit";
      }
    });
  },
};
</script>