<template>
  <div class="mb-lg-0">
    <div>
      <div class="item2-gl">
        <div class="row">
          <template v-if="list == null || (list.length == 0 && !isLoad)">
            <div class="text-center"><h3>Sonuç Bulunamadı</h3></div>
          </template>
          <template v-if="list == null || (list.length == 0 && isLoad)">
            <div class="text-center"><h3>Sonuçlar getiriliyor...</h3></div>
          </template>
          <div id="myCarousel" class="owl-carousel owl-carousel-icons3">
            <div v-for="(item, i) in list" :key="'user' + i">
              <div class="item">
                <div class="card tItem overflow-hidden">
                  <div class="item-card9-imgs">
                    <router-link
                      :to="'/terapist/' + item.shortlink"
                    ></router-link>
                    <lazy-image
                      :src="
                        'https://storage.terapivitrini.com/' +
                        item.profile_image_url
                      "
                      :alt="item.fullname"
                      class="w-100"
                    />
                  </div>
                  <div class="card-body">
                    <div class="item-card2">
                      <div class="item-card2-desc text-center">
                        <div class="item-card2-text">
                          <router-link
                            :to="'/terapist/' + item.shortlink"
                            class="text-dark"
                            ><h2
                              class="font-weight-bold mb-1"
                              style="font-size: 20px"
                            >
                              {{ item.fullname }}
                            </h2></router-link
                          >
                          <StarPos
                            :pos="
                              Math.ceil(item.total_score / item.total_comment)
                            "
                            :id="item.user_id"
                          />
                        </div>
                        <p class="fs-16">{{ item.expert }}</p>
                        <div class="btn-group">
                          <router-link
                            :to="'/terapist/' + item.shortlink"
                            class="btn btn-primary btn-sm"
                            >Profil Detay</router-link
                          >
                          <button
                            class="btn btn-warning btn-sm"
                            @click="appointmentDialogOpen(item.user_id)"
                          >
                            Randevu Al
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" id="appointmentDialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="background: none; border: none">
              <div class="modal-body">
                <Sidebar :userid="userId" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <style>
.owl-dots {
  margin-top: -20px !important;
  margin-bottom: 10px !important;
}
</style>
  <script>
import Sidebar from "../../ProfileDetail/Components/Sidebar.vue";
import StarPos from "../../../components/StarPos.vue";
export default {
  props: ["list", "isLoad"],
  components: {
    Sidebar,
    StarPos,
  },
  mounted() {
    $("#appointmentDialog").on("show.bs.modal", function (e) {
      window.location.hash = "modal";
    });

    $(window).on("hashchange", function (event) {
      if (window.location.hash != "#modal") {
        $("#appointmentDialog").modal("hide");
      }
    });
  },
  data() {
    return {
      userId: null,
    };
  },
  methods: {
    appointmentDialogOpen(id) {
      $("#appointmentDialog").modal("show");
      this.userId = id;
    },
    appointmentDialogClose() {
      $("#appointmentDialog").modal("hide");
    },
  },
};
</script>