import { render, staticRenderFns } from "./CenterDetailProfileList.vue?vue&type=template&id=12384945"
import script from "./CenterDetailProfileList.vue?vue&type=script&lang=js"
export * from "./CenterDetailProfileList.vue?vue&type=script&lang=js"
import style0 from "./CenterDetailProfileList.vue?vue&type=style&index=0&id=12384945&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports